<template>
    <b-row v-if="slimes.length" class="ml-1 mr-1">
        <slime-block :slime="slime" v-for="slime in slimes" :key="'slime_' + slime.id"/>
    </b-row>
    <p v-else>Vous n'avez aucun slime pour le moment</p>
</template>

<script>
    const SlimeBlock = () => import('./SlimeBlock');

    import alert         from '@/util/alert';
    import {apiPath}     from '@/util/http';
    import {momentDates} from '@/util/slime';

    export default {
        name: "SlimeBlocks",
        components: {SlimeBlock},
        data: () => ({
            slimes: []
        }),
        methods: {
            loadData() {
                alert.loading();
                this.axios.get(apiPath('list_own_slimes'))
                    .then(response => this.slimes = momentDates(response.data))
                    .catch(() => this.$toaster.error('Impossible de charger la liste des slimes'))
                    .finally(alert.stopLoading);
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>